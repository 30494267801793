import { useState, useEffect } from "react";
import axios from "axios";

export interface DimensionValue {
  value: string;
  oneValue: string;
}

export interface MetricValue {
  value: string;
  oneValue: string;
}

export interface PageView {
  dimensionValues: DimensionValue[];
  metricValues: MetricValue[];
  month: string;
}

export default function PageViewsTable() {
  const [pageViews, setPageViews] = useState<PageView[]>([]);
  const [selectedMonth, setSelectedMonth] = useState("2024-06-01");

  useEffect(() => {
    const fetchPageViews = async () => {
      const url = process.env.REACT_APP_API_URL;

      const response = await axios.get(`${url}/api/marketing/total-page-views`);
      setPageViews(response.data.data.totalPageViews);
    };

    fetchPageViews();
  }, []);
  const months = [
    // { label: "Ocak 2023", value: "2023-01-01" },
    // { label: "Şubat 2023", value: "2023-02-01" },
    // { label: "Mart 2023", value: "2023-03-01" },
    // { label: "Nisan 2023", value: "2023-04-01" },
    // { label: "Mayıs 2023", value: "2023-05-01" },
    // { label: "Haziran 2023", value: "2023-06-01" },
    // { label: "Temmuz 2023", value: "2023-07-01" },
    // { label: "Ağustos 2023", value: "2023-08-01" },
    // { label: "Eylül 2023", value: "2023-09-01" },
    // { label: "Ekim 2023", value: "2023-10-01" },
    // { label: "Kasım 2023", value: "2023-11-01" },
    // { label: "Aralık 2023", value: "2023-12-01" },
    { label: "Ocak 2024", value: "2024-01-01" },
    { label: "Şubat 2024", value: "2024-02-01" },
    { label: "Mart 2024", value: "2024-03-01" },
    { label: "Nisan 2024", value: "2024-04-01" },
    { label: "Mayıs 2024", value: "2024-05-01" },
    { label: "Haziran 2024", value: "2024-06-01" },
    { label: "Temmuz 2024", value: "2024-07-01" },
    { label: "Ağustos 2024", value: "2024-08-01" },
    { label: "Eylül 2024", value: "2024-09-01" },
    { label: "Ekim 2024", value: "2024-10-01" },
    { label: "Kasım 2024", value: "2024-11-01" },
    { label: "Aralık 2024", value: "2024-12-01" },
  ];

  const filteredPageViews = pageViews.filter(
    (view) => view.month === selectedMonth
  );

  return (
    <div>
      <div>
        {/* Ay seçici */}
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          className="bg-yellow-400 text-black rounded-full py-2 pl-4 pr-8 float-right"
        >
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
      </div>
      <div className="bg-white">
        <div className="px-4 py-4 sm:px-6 lg:px-8">
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Sayfa
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900"
                      >
                        Toplam Sayfa Görüntülenme
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900"
                      >
                        Kullanıcı Sayısı
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900"
                      >
                        Kullanıcı Başına Görüntülenme Sayısı
                      </th>
                      {/* <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900"
                    >
                      Ortalama Etkileşim Süresi
                    </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredPageViews.map((view, index) => (
                      <tr key={index}>
                        <td className="px-2 py-2 text-sm font-medium text-gray-900 overflow-hidden ellipsis max-w-sm">
                          {view.dimensionValues[0].value}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900">
                          {view.metricValues[0].value}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900">
                          {view.metricValues[1].value}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900">
                          {parseFloat(view.metricValues[2].value).toFixed(2)}
                        </td>
                        {/* <td className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900">
                      {formatDuration(parseFloat(view.metricValues[3].value))}
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
